<template>
    <div class="p-8 rounded-md bg-custom-secondary text-custom-contrastSecondary" :class="{ 'darkThemeClass' : darkTheme, 'cleanThemeClass' : cleanTheme }">
      <Icon v-if="iconName" :name="iconName" :size="iconSize" class="opacity-40"></Icon>
      <slot name="description" />
      <Txt extra-small>{{ description }}</Txt>
    </div>
</template>

<script>
export default {
  props: {
    description: {
      type: String,
      default: null,
    },
    iconName: {
      type: String,
      default: null,
    },
    iconSize: {
      type: Number,
      default: 32,
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
    cleanTheme: {
      type: Boolean,
      default: false,
    }
  }
};
</script>
<style scoped>
  .darkThemeClass {
    @apply !bg-black !text-white;
  }
  .cleanThemeClass {
    @apply !bg-white !text-black;
  }
</style>
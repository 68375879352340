import Vue from 'vue'
import { Roles } from '@/common/constants'
import i18n from './plugins/i18n'

Vue.prototype.$roleMap = {
  [`${Roles.Admin}`]:          { baseRoute: '/admin', label: i18n.t(`Roles.RoleNames.${Roles.Admin}`) },
  [`${Roles.CSM}`]:            { baseRoute: '/csm', label: i18n.t(`Roles.RoleNames.${Roles.CSM}`) },
  [`${Roles.MSP}`]:            { baseRoute: '/msp', label: i18n.t(`Roles.RoleNames.${Roles.MSP}`) },
  [`${Roles.ContentManager}`]: { baseRoute: '/cm', label: i18n.t(`Roles.RoleNames.${Roles.ContentManager}`) },
  [`${Roles.CompanyAdmin}`]:   { baseRoute: '/companyadmin', label: i18n.t(`Roles.RoleNames.${Roles.CompanyAdmin}`) },
  [`${Roles.Supervisor}`]:     { baseRoute: '/supervisor', label: i18n.t(`Roles.RoleNames.${Roles.Supervisor}`) },
  [`${Roles.Teamleader}`]:     { baseRoute: '/teamleader', label: i18n.t(`Roles.RoleNames.${Roles.Teamleader}`) },
  [`${Roles.Student}`]:        { baseRoute: '/student', label: i18n.t(`Roles.RoleNames.${Roles.Student}`) },
}

import { DateTime, Duration } from 'luxon'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import eventBus from './plugins/eventBus'
import methods from './common/methods'
import isoCountriesLanguages from 'iso-countries-languages'
import router from '@/router/router'
import './plugins/filters'

Vue.use(Vuelidate)

library.add(fas)
library.add(fab)

Vue.prototype.$eventBus              = eventBus
Vue.prototype.$methods               = methods
Vue.prototype.$dateTime              = DateTime
Vue.prototype.$duration              = Duration
Vue.prototype.$isoCountriesLanguages = isoCountriesLanguages

import Accordion from "@/components/atoms/Accordion.vue";
import Alert from '@/components/atoms/Alert'
import Badge from '@/components/atoms/Badge'
import Button from '@/components/atoms/Button'
import Banner from '@/components/atoms/Banner'
import CategoryLabel from '@/components/atoms/CategoryLabel'
import Card from '@/components/atoms/Card'
import Calendar from '@/components/atoms/Calendar'
import CardsGrid from '@/components/atoms/CardsGrid'
import Checkbox from '@/components/atoms/Checkbox'
import CircularProgress from '@/components/atoms/CircularProgress'
import LvColorpicker from 'lightvue/color-picker';
import LvProgressBar from 'lightvue/progress-bar';
import ContextualNavigation from '@/components/atoms/ContextualNavigation'
import ContextualNavigationItem from '@/components/atoms/ContextualNavigationItem'
import CTA from '@/components/atoms/CTA'
import DatePicker from '@/components/atoms/DatePicker'
import FormGroup from '@/components/atoms/FormGroup'
import Icon from '@/components/atoms/Icon'
import IconStatus from '@/components/atoms/IconStatus'
import Input from '@/components/atoms/Input'
import InputWrapper from '@/components/atoms/InputWrapper'
import Link from '@/components/atoms/Link'
import ListSelect from '@/components/atoms/ListSelect'
import Loader from '@/components/atoms/Loader'
import Support from '@/components/atoms/Support.vue'
import Menu from '@/components/atoms/Menu'
import MenuItem from '@/components/atoms/MenuItem'
import MenuToggleButton from '@/components/atoms/MenuToggleButton'
import Modal from '@/components/atoms/Modal'
import Navbar from '@/components/atoms/Navbar.vue'
import NavbarItem from '@/components/atoms/NavbarItem.vue'
import Notifications from '@/components/atoms/Notifications'
import PageNav from '@/components/atoms/PageNav'
import PageNavItem from '@/components/atoms/PageNavItem'
import Pagination from '@/components/atoms/Pagination'
import ProfileMenu from '@/components/atoms/ProfileMenu'
import Progress from '@/components/atoms/Progress'
import Radio from '@/components/atoms/Radio'
import RankingList from '@/components/atoms/RankingList'
import Select from '@/components/atoms/Select'
import Status from '@/components/atoms/Status'
import Table from '@/components/atoms/Table'
import TableCell from '@/components/atoms/TableCell'
import TableRow from '@/components/atoms/TableRow'
import Textarea from '@/components/atoms/Textarea'
import Txt from '@/components/atoms/Txt'
import TodoList from '@/components/atoms/TodoList'
import TodoListItem from '@/components/atoms/TodoListItem'
import Toggle from '@/components/atoms/Toggle'
import Tooltip from '@/components/atoms/Tooltip'
import InputTags from '@/components/atoms/InputTags'
import Tabs from '@/components/atoms/Tabs'
import Tag from '@/components/atoms/Tag'

import Logo from '@/components/assets/Logo'
import LogoSymbol from '@/components/assets/LogoSymbol'

import CyberRiskCard from '@/components/cards/CyberRiskCard'
import ModuleCard from '@/components/cards/ModuleCard'
import TemplateCard from '@/components/cards/TemplateCard'
import LandingPageCard from '@/components/cards/LandingPageCard'
import ProgressCard from '@/components/cards/ProgressCard'
import RiskRatingCard from '@/components/cards/RiskRatingCard'
import RemediationWidget from '@/components/cards/RemediationWidget'
import RemediatedWidget from '@/components/cards/RemediatedWidget'
import PlaceholderEmptyChartCard from '@/components/cards/PlaceholderEmptyChartCard'

import BarChart from '@/components/charts/BarChart'
import BCMockUp from '@/components/charts/BCMockUp'
import LineChart from '@/components/charts/LineChart'
import PCMockUp from '@/components/charts/PCMockUp'
import PieChart from '@/components/charts/PieChart'
import RadarChart from '@/components/charts/RadarChart'
import MockedCyberChainStrength from '@/components/charts/dashboard/CCSMockUp'
import CSMockUp from '@/components/charts/dashboard/CSMockUp'
import CgBarChart from '@/components/charts/dashboard/CgBarChart'
import CgLineChart from '@/components/charts/dashboard/CgLineChart'
import WeakUserRate from "@/components/charts/dashboard/WeakUserRate";
import ReliabilityScore from "@/components/charts/dashboard/ReliabilityScore";
import RiskByLocation from "@/components/charts/dashboard/RiskByLocation";
import RiskEvolution from "@/components/charts/dashboard/RiskEvolution";
import RiskByTags from "@/components/charts/dashboard/RiskByTags";

import ClickRateByCampaign from "@/components/charts/analytics/ClickRateByCampaign";
import AnalyticsPie from "@/components/charts/analytics/AnalyticsPie";
import NestedPie from "@/components/charts/analytics/NestedPie";
import ClickRateByTag from "@/components/charts/analytics/ClickRateByTag";
import ClickersByTag from "@/components/charts/analytics/ClickersByTag";
import ClickRateByTemplate from "@/components/charts/analytics/ClickRateByTemplate";
import ClickRateByTarget from "@/components/charts/analytics/ClickRateByTarget";
import ClickRateByOrgHeatmap from '@/components/charts/analytics/ClickRateByOrgHeatmap';
import GlobalStats from '@/components/charts/analytics/GlobalStats';

import PageHead from '@/components/layout/PageHead'
import PageWrapper from '@/components/layout/PageWrapper'
import SideWrapper from '@/components/layout/SideWrapper'
import UserSidebar from '@/components/layout/UserSidebar'
import Vuelidate from 'vuelidate'

import MailModal from '@/components/modals/MailModal'
import ModalForm from '@/components/modals/ModalForm'
import LangModal from '@/components/modals/LangModal'
import TemplateModal from '@/components/modals/TemplateModal'
import AnalyticsCard from '@/components/cards/AnalyticsCard'
import LandingPageModal from '@/components/modals/LandingPageModal'
import Editor from '@/components/Editor'
import PolicyModal from '@/components/PolicyModal'
import FileUploadModal from '@/components/modals/FileUploadModal'
import DateRangePicker from '@/components/atoms/DateRangePicker'

import ReportFilter from '@/components/filters/ReportFilter'

import Widget from "@/components/atoms/Widget.vue";


Vue.component('Accordion', Accordion)
Vue.component('Alert', Alert)
Vue.component('Badge', Badge)
Vue.component('Banner', Banner)
Vue.component('Button', Button)
Vue.component('CategoryLabel', CategoryLabel)
Vue.component('Card', Card)
Vue.component('Calendar', Calendar)
Vue.component('CardsGrid', CardsGrid)
Vue.component('Checkbox', Checkbox)
Vue.component('CircularProgress', CircularProgress)
Vue.component('ColorPicker', LvColorpicker)
Vue.component('ProgressBar', LvProgressBar)
Vue.component('ContextualNavigation', ContextualNavigation)
Vue.component('ContextualNavigationItem', ContextualNavigationItem)
Vue.component('CTA', CTA)
Vue.component('DatePicker', DatePicker)
Vue.component('DateRangePicker', DateRangePicker)
Vue.component('FormGroup', FormGroup)
Vue.component('Icon', Icon)
Vue.component('IconStatus', IconStatus)
Vue.component('Input', Input)
Vue.component('InputWrapper', InputWrapper)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('Link', Link)
Vue.component('ListSelect', ListSelect)
Vue.component('Loader', Loader)
Vue.component('Support', Support)
Vue.component('Menu', Menu)
Vue.component('MenuItem', MenuItem)
Vue.component('MenuToggleButton', MenuToggleButton)
Vue.component('Modal', Modal)
Vue.component('Navbar', Navbar)
Vue.component('NavbarItem', NavbarItem)
Vue.component('Notifications', Notifications)
Vue.component('PageNav', PageNav)
Vue.component('PageNavItem', PageNavItem)
Vue.component('Pagination', Pagination)
Vue.component('ProfileMenu', ProfileMenu)
Vue.component('Progress', Progress)
Vue.component('Radio', Radio)
Vue.component('RankingList', RankingList)
Vue.component('Select', Select)
Vue.component('Status', Status)
Vue.component('Table', Table)
Vue.component('TableCell', TableCell)
Vue.component('TableRow', TableRow)
Vue.component('Textarea', Textarea)
Vue.component('Txt', Txt)
Vue.component('TodoList', TodoList)
Vue.component('TodoListItem', TodoListItem)
Vue.component('Toggle', Toggle)
Vue.component('Tooltip', Tooltip)
Vue.component('InputTags', InputTags)
Vue.component('Tabs', Tabs)
Vue.component('Tag', Tag)

Vue.component('Logo', Logo)
Vue.component('LogoSymbol', LogoSymbol)

Vue.component('CyberRiskCard', CyberRiskCard)
Vue.component('ModuleCard', ModuleCard)
Vue.component('TemplateCard', TemplateCard)
Vue.component('LandingPageCard', LandingPageCard)
Vue.component('ProgressCard', ProgressCard)
Vue.component('RiskRatingCard', RiskRatingCard)
Vue.component('RemediationWidget', RemediationWidget)
Vue.component('RemediatedWidget', RemediatedWidget)
Vue.component('ClickRateByOrgHeatmap', ClickRateByOrgHeatmap)
Vue.component('GlobalStats', GlobalStats);
Vue.component('PlaceholderEmptyChartCard',PlaceholderEmptyChartCard);

Vue.component('BarChart', BarChart)
Vue.component('BCMockUp', BCMockUp)
Vue.component('LineChart', LineChart)
Vue.component('PCMockUp', PCMockUp)
Vue.component('PieChart', PieChart)
Vue.component('RadarChart', RadarChart)

Vue.component('PageHead', PageHead)
Vue.component('PageWrapper', PageWrapper)
Vue.component('SideWrapper', SideWrapper)
Vue.component('UserSidebar', UserSidebar)

Vue.component('MailModal', MailModal)
Vue.component('ModalForm', ModalForm)
Vue.component('LangModal', LangModal)
Vue.component('TemplateModal', TemplateModal)

Vue.component('AnalyticsCard', AnalyticsCard)
Vue.component('MockedCyberChainStrength', MockedCyberChainStrength)
Vue.component('CSMockUp', CSMockUp)
Vue.component('CgBarChart', CgBarChart)
Vue.component('CgLineChart', CgLineChart)
Vue.component('WeakUserRate', WeakUserRate)
Vue.component('ReliabilityScore', ReliabilityScore)
Vue.component('RiskByLocation', RiskByLocation)
Vue.component('RiskEvolution', RiskEvolution)
Vue.component('RiskByTags', RiskByTags)

Vue.component('ClickRateByCampaign', ClickRateByCampaign)
Vue.component('AnalyticsPie', AnalyticsPie)
Vue.component('ClickRateByTag', ClickRateByTag)
Vue.component('ClickersByTag', ClickersByTag)
Vue.component('ClickRateByTemplate', ClickRateByTemplate)
Vue.component('ClickRateByTarget', ClickRateByTarget)
Vue.component('NestedPie', NestedPie)

Vue.component('LandingPageModal', LandingPageModal)
Vue.component('Editor', Editor)
Vue.component('PolicyModal', PolicyModal)
Vue.component('FileUploadModal', FileUploadModal)

Vue.component('ReportFilter', ReportFilter)
Vue.component('Widget', Widget)

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

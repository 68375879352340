import { apiClient } from '@/plugins/api'

export default {
  ping() {
    return apiClient.get(`admin/ping`)
  },
  getCompany(companyUUID, withCompanyOptions = false) {
    let opt = {}

    if (withCompanyOptions)
      opt.params = { withCompanyOptions: true}

    return apiClient.get(`admin/company/${companyUUID}`, opt)
  },
  createCompany(companyDetails) {
    return apiClient.post(`admin/company`, companyDetails)
  },
  editCompany(companyUUID, payload) {
    return apiClient.put(`admin/company/${companyUUID}`, payload)
  },
  deleteCompany(companyUUID, keepdata = false) {
    const opt = {
      data: { keepdata: keepdata }
    }

    return apiClient.delete(`admin/company/${companyUUID}`, opt)
  },
  uploadFile(companyUUID, payload) {
    return apiClient.post(`admin/company/${companyUUID}/upload`, payload)
  },
  getCompanies(params) {
    const opt = { params }
    return apiClient.get(`admin/company`, opt)
  },
  autoAssignLicenses(companyUUID, licenseId, payload) {
    return apiClient.post(`admin/company/${companyUUID}/license/${licenseId}/account/all`, payload)
  },
  updateLicense(companyId, licenseId, payload) {
    return apiClient.put(`admin/company/${companyId}/license/${licenseId}`, payload)
  },
  autoRemoveLicenses(companyUUID, payload) {
    const opt = { data: payload }
    return apiClient.delete(`admin/company/${companyUUID}/license/account/all`, opt)
  },
  assignLicenseToAccounts(companyUUID, licenseId, payload) {
    return apiClient.post(`admin/company/${companyUUID}/license/${licenseId}/account`, payload)
  },
  removeLicenseFromAccounts(companyUUID, licenseId, payload) {
    const opt = { data: payload }
    return apiClient.delete(`admin/company/${companyUUID}/license/${licenseId}/account`, opt)
  },
  removeAllLicenseFromAccounts(companyUUID, payload) {
    const opt = { data: payload }
    return apiClient.delete(`admin/company/${companyUUID}/license/account/all`, opt)
  },
  getLicense(companyId,licenseId) {
    return apiClient.get(`admin/company/${companyId}/license/${licenseId}`)
  },
  getLicenses(companyUUID) {
    return apiClient.get(`admin/company/${companyUUID}/license`)
  },
  createLicense(companyUUID, payload) {
    return apiClient.post(`admin/company/${companyUUID}/license`, payload)
  },
  getAccountsByLicense(licenseId, companyId, show_accounts_not_licensed, paginate, search, page, sort_key, sort_dir, _limit) {
    const opts = {
      params: {
        show_accounts_not_licensed,
        paginate,
        page,
        sort_key,
        sort_dir,
        _limit,
        search
      }
    }
    return apiClient.get(`admin/company/${companyId}/license/${licenseId}/account`, opts)
  },
  getTopRankedUsers(companyUUID, queryParams) {
    const opt = { params: queryParams}
    return apiClient.get(`report/statistics/company/${companyUUID}/global-users-ranking`, opt)
  },
  getTopRankedAnonymizedUsers(companyUUID, accountUUID, queryParams) {
    const opt = {
      params: {
        ...queryParams,
        accountId: accountUUID
      }
    }
    return apiClient.get(`report/statistics/company/${companyUUID}/global-anon-users-ranking`, opt);
  },
  getCompactRankedUsers(companyUUID, queryParams) {
    const opt = { params: queryParams}
    return apiClient.get(`report/statistics/company/${companyUUID}/compact-users-ranking`, opt)
  },
  getCompactRankedAnonymizedUsers(companyUUID, accountUUID, team = null) {
    const opt = {
      params: {
        accountId: accountUUID
      }
    }
    if(team)
    {
      opt.params.team =  team
    }
    return apiClient.get(`report/statistics/company/${companyUUID}/compact-anon-users-ranking`, opt);
  },
  getTopRankedUsersInTeam(companyUUID, teamName, queryParams) {
    const opt = {
      params: {
        ...queryParams,
        team: teamName
      }
    }

    return apiClient.get(`report/statistics/company/${companyUUID}/team-users-ranking`, opt)
  },
  getTopRankedAnonymizedUsersInTeam(companyUUID, teamName, accountUUID, queryParams) {
    const opt = {
      params: {
        ...queryParams,
        team: teamName,
        accountId: accountUUID,
      }
    }

    return apiClient.get(`report/statistics/company/${companyUUID}/team-anon-users-ranking`, opt)
  },
  getTopRankedTeams(companyUUID, queryParams) {
    const opt = { params: queryParams}
    return apiClient.get(`report/statistics/company/${companyUUID}/global-teams-ranking`, opt)
  },
  getTopRankedAnonymizedTeams(companyUUID, team, queryParams) {
    const opt = {
      params: {
        ...queryParams,
        team: team
      }
    }
    return apiClient.get(`report/statistics/company/${companyUUID}/global-anon-teams-ranking`, opt)
  },
  getCompactRankedTeams(companyUUID, team) {
    const opt = {
      params: {
        companyId: companyUUID,
        team: team
      }
    }
    return apiClient.get(`report/statistics/company/${companyUUID}/compact-teams-ranking`, opt)
  },
  getCompactRankedAnonymizedTeams(companyUUID, team, accountUUID = null) {
    let params = {};
    params.team = team;
    if (accountUUID) { params.accountId = accountUUID; }
    return apiClient.get(`report/statistics/company/${companyUUID}/compact-anon-teams-ranking`, {params})
  },
  addTags(companyUUID, payload) {
    return apiClient.post(`admin/company/${companyUUID}/tags`, payload)
  },
  getTags(companyUUID, queryParams) {
    // queryParams = { fullinfo: true }
    const opt = { params: queryParams }
    return apiClient.get(`admin/company/${companyUUID}/tags`, opt)
  },
  scheduleLevelAssignmentToCompany(payload) {
    return apiClient.post(`scheduler/job`, payload)
  },
  triggerLevelAssignmentToCompany(payload) {
    return apiClient.post(`scheduler/scheduledJob`, payload)
  },
  uploadCompanyLogo(companyUUID, payload) {
    return apiClient.post(`admin/company/${companyUUID}/upload`, payload)
  },
  getRolesList() {
    return apiClient.get(`admin/role`)
  },
  getRoleById(roleId) {
    return apiClient.get(`admin/role/${roleId}`)
  },
  getOptions(lang = null) {
    const opt = {
      params: {
        lang: lang,
      },
    }
    return apiClient.get(`admin/company_option`, opt)
  },
  getCompanyOption(lang = null, companyId, optionCode) {
    return apiClient.get(`admin/company/${companyId}/company_option_value/${optionCode}`,
      { params: { lang: lang } })
  },
  getCompanyOptionValues(lang = null, companyId) {
    const opt = {
      params: {
        lang: lang,
      },
    }
    return apiClient.get(`admin/company/${companyId}/company_option_value`, opt)
  },
  updateCompanyOptionValues(companyId, payload) {
    const opt = {
      company_option_values: payload,
    }
    return apiClient.put(`admin/company/${companyId}/company_option_value`, opt)
  },
  createOption(option_code, option_descr, option_group_id, option_datatype, option_formtype, default_value, select_values, modified_by_uuid) {
    const opt = {
      option_code: option_code,
      option_descr: option_descr,
      option_group_id: option_group_id,
      option_datatype: option_datatype,
      option_formtype: option_formtype,
      default_value: default_value,
      select_values: select_values,
      modified_by_uuid: modified_by_uuid,
    }
    return apiClient.post(`admin/company_option`, opt)
  },
  editOption(companyOptionId, payload) {
    return apiClient.put(`admin/company_option/${companyOptionId}`, payload)
  },
  deleteOption(companyOptionId) {
    return apiClient.delete(`admin/company_option/${companyOptionId}`)
  },
  getOptionsGroups(lang = null) {
    const opt = {
      params: {
        lang: lang,
      },
    }
    return apiClient.get(`admin/company_option_group`, opt)
  },
  createOptionGroup(optionGroupName, lang) {
    const opt = {
      option_group_name: optionGroupName,
      lang: lang,
    }
    return apiClient.post(`admin/company_option_group`, opt)
  },
  updateOptionGroup(companyOptionGroupId, payload) {
    return apiClient.put(`admin/company_option_group/${companyOptionGroupId}`, payload)
  },
  deleteOptionGroup(companyOptionGroupId) {
    return apiClient.delete(`admin/company_option_group/${companyOptionGroupId}`)
  },
  getServicesList() {
    return apiClient.get(`admin/service`)
  },
  getTeams(companyUUID) {
    return apiClient.get(`admin/company/${companyUUID}/teams`)
  },
  getCampaignList(companyUUID, queryParams) {
    const opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyUUID}/campaign`, opt)
  },
  getCompanyTestTargets(companyUUID, queryParams) {
    return apiClient.get(`phishing/company/${companyUUID}/test`, { params: queryParams });
  },
  getTemplateLanguages(companyUUID) {
    // RFI: provide a mean to assign some templates to a specific Company only
    return apiClient.get(`phishing/company/${companyUUID}/template_languages`)
  },
  getLandingPageLanguages(companyUUID) {
    return apiClient.get(`phishing/company/${companyUUID}/landing_page_languages`)
  },
  getTargetsNumber(companyUUID) {
    return apiClient.get(`phishing/company/${companyUUID}/targets_number`)
  },
  getTargetCountries(companyUUID) {
    return apiClient.get(`phishing/company/${companyUUID}/country`)
  },
  hasRemediationTab(companyId) {
    return apiClient.get(`phishing/company/${companyId}/remediations/tab`)
  },
  cleanUpPendingRemediations(companyId) {
    return apiClient.get(`phishing/company/${companyId}/remediations/cleanup`)
  },
  getCurrentRemediationCampaigns(companyId) {
    return apiClient.get(`/phishing/company/${companyId}/remediations`)
  },
  getCompanyOrgLabels(companyId) {
    return apiClient.get(`phishing/company/${companyId}/orgLabels`)
  },
  getCompanyOrgValues(companyId) {
    return apiClient.get(`phishing/company/${companyId}/orgValues`)
  },
  getCompletedFinalRemediationCampaigns(companyId, type, params) {
    return apiClient.get(`phishing/company/${companyId}/remediation/${type}`, { params })
  },
  createRemediationCampaign(companyId, type) {
    return apiClient.put(`phishing/company/${companyId}/remediation/${type}`)
  },
  updateCompanyTestTargets(companyId, targets, campaignId, campaignType, saveOnly) {
		return apiClient.put(`phishing/company/${companyId}/test`, { targets: targets, campaignId: campaignId, campaignType: campaignType, saveOnly: saveOnly });
	},
  getRemediationLightTargets(companyId, queryParams) {
    const opt = { params: queryParams }
    return apiClient.get(`phishing/company/${companyId}/remediations/filters`, opt)
  },
  getWelcomeMailStats(companyId) {
    return apiClient.get(`admin/company/${companyId}/welcome-mail-stats`)
  },
  getCompanyRemediationAccounts(remediation_type, company_id) {
    return apiClient.get(`remediation/${remediation_type}/company/${company_id}/account`)
  },
  getCompanyRemediations(companyId) {
    return apiClient.get(`remediation/company/${companyId}/remediation`)
  },
  createCompanyRemediation(companyId, payload) {
    return apiClient.post(`remediation/company/${companyId}/remediation`, payload)
  },
  getParentCompanyLogo(companyId) {
    return apiClient.get(`admin/company/${companyId}/parent-logo`)
  },
  getSSOIdentityProvider(companyName) {
    return apiClient.get(`keycloak/company/${companyName}/identity-provider`)
  },
  createSSOIdentityProvider(companyName, payload) {
    return apiClient.post(`keycloak/company/${companyName}/identity-provider`, payload)
  },
  updateSSOIdentityProvider(companyName, payload) {
    return apiClient.put(`keycloak/company/${companyName}/identity-provider/saml`, payload)
  },
  mapSSOFields(companyName, payload) {
    return apiClient.post(`keycloak/company/${companyName}/identity-provider/saml/mappers`, payload)
  },
  deleteSSOIdentityProvider(companyName, idpName = 'saml') {
    return apiClient.delete(`keycloak/company/${companyName}/identity-provider/${idpName}`)
  },
  syncLdapConfig(companyId) {
    return apiClient.post(`admin/company/${companyId}/import-ldap-users`)
  },
  ldapConfigKeyCloakCheck(companyName) {
    return apiClient.get(`keycloak/company/${companyName}/user-federation/ldap`)
  },
  getScimStatus(companyId) {
    return apiClient.get(`admin/company/${companyId}/scim`)
  },
  updateScimStatus(companyId) {
    return apiClient.post(`admin/company/${companyId}/scim`)
  },
  generateScimToken(companyName) {
    return apiClient.post(`keycloak/company/${companyName}/scim/token`)
  },
  getLicenseHistory(companyId, params) {
    const opt = { params }
    return apiClient.get(`admin/company/${companyId}/license-history`, opt)
  },
}
